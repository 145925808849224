@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext");

body {
    font-family: Merriweather;
}

h2 {
    font-family: "Open Sans";
    color: theme("colors.blue");
    text-transform: uppercase;
    font-weight: bold;
    font-size: theme("fontSize.sm");
}

a {
    color: theme("colors.red.800");
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}
